import { useState, useEffect } from 'react';
import moment from 'moment';

export default ({ feedId, story, StoryEl }) => {

    let date;

    if (Number(story.saveDate) > Date.now()) {
        date = Date.now();
    } else {
        date = story.saveDate;
    }

	const saveDateD = new Date(Number(date));
	const localeDateString = saveDateD.toLocaleString();

	const [
		momentDateString,
		setMoment
	] = useState(moment(saveDateD).fromNow());

	useEffect(
		() => {
			const intervalId = setInterval(
				() => setMoment(moment(saveDateD).fromNow()),
				30000
			);
			return () => clearInterval(intervalId);
		},
		[]
	);

	return (
		<StoryEl
			feedId={ feedId }
			story={ story }
			localeDateString={ localeDateString }
			momentDateString={ momentDateString }
		/>
	);
};
