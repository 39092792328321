import { createBrowserHistory, createPath } from 'history';
import UniversalRouter from 'universal-router';
import axios from 'axios';
import { createRoot } from 'react-dom/client';
import { library } from '@fortawesome/fontawesome-svg-core';
import HistoryManager from '@lib/history-manager';
import routes from '@ui/routes';
import _providers from '@lib/providers';
import makeRenderer from '@ui/renderer';

import {
	faAlignJustify,
	faArrowLeftLong,
	faArrowRightFromBracket,
	faBars,
	faBookOpen,
	faCaretLeft,
	faCaretRight,
    faCheck,
	faChevronLeft,
	faChevronRight,
	faChevronDown,
	faCircleNotch,
    faEllipsisVertical,
    faMagnifyingGlass,
    faPencil,
	faPlusMinus,
    faRss,
	faTableList,
	faTableColumns,
    faThumbtack,
    faTowerCell,
    faTrashCan,
    faXmark,
} from '@fortawesome/free-solid-svg-icons';

library.add(
	faAlignJustify,
	faArrowLeftLong,
	faArrowRightFromBracket,
	faBars,
	faBookOpen,
	faCaretLeft,
	faCaretRight,
    faCheck,
	faChevronLeft,
	faChevronRight,
	faChevronDown,
	faCircleNotch,
    faEllipsisVertical,
    faMagnifyingGlass,
    faPencil,
	faPlusMinus,
    faRss,
	faTableList,
	faTableColumns,
    faThumbtack,
    faTowerCell,
    faTrashCan,
    faXmark,
);

const makeRenderLocation = (router, render, history) => async (ctx) => {

    const { location: nextLocation } = ctx;
	const component = await router.resolve(nextLocation);

	if (component.redirect) {
		return history.replace(component.redirect);
	}

	render(component);
};

const makeRootDOMEl = id => {
    const container = document.getElementById(id);
	const rootEl = createRoot(container);
    return rootEl;
};

export default () => {

    const browserHistory = createBrowserHistory();
    const historyManager = HistoryManager(browserHistory);
    const router = new UniversalRouter(
        routes,
    );

	const rootDOMEl = makeRootDOMEl('root');

    const providers = Object.values(_providers);

    const renderer = makeRenderer(rootDOMEl, providers);
    const renderLocation = makeRenderLocation(router, renderer, browserHistory);

	historyManager.addListener(renderLocation);
	historyManager.triggerLocation({ location: browserHistory.location });
	historyManager.listen();
};
