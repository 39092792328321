export const createFeed = async ({ coll, addFeed, m: { ObjectId } }, { feed }) => {

	feed.publicationId = new ObjectId(feed.publicationId);

	const {
		insertedCount,
		ops,
		insertedId,
		connection,
		result
	} = await coll.insertOne(feed);

	await addFeed({ publicationId: feed.publicationId, feedId: feed._id });

	return insertedCount;
};

export const getFeed = async ({ coll, u }, { feedId }) => {
    return coll.findOne(u.objectify({ _id: feedId }));
};

const paginationDefaults = {
	pageSize: 50,
	page: 1,
	sort: undefined,
    filters: {},
};

export const getFeeds = async ({ coll, u, r, m: { ObjectId } }, { q, pageOpts, withInput = {} }) => {

	const { sort, filters, page, pageSize } = r.mergeRight(paginationDefaults, pageOpts);

    let pipeline = [];

    let project = {
        _id: 1,
        url: 1,
        title: 1,
        description: 1,
        aggregate: 1,
        live: 1,
        enabled: 1,
        lastPolled: 1,
        lastSuccessfulPoll: 1,
        fetchErrors: 1,
        lastError: 1,
        lastErrorDate: 1,
    };

    let match = {};
    let sortObj = {};

    if (pageOpts && page && pageSize) {

        const lists = [ 'publication' ];

        match = lists.reduce(
            (ms, key) => {
                if (key in filters) {
                    if (key === 'publication') {
                        return {
                            ...ms,
                            'publication._id': {
                                $in: filters[ key ].map(id => new ObjectId(id)),
                            }
                        };
                    }
                    return {
                        ...ms,
                        [ key ]: { $in: filters[ key ] },
                    };
                }
                return ms;
            },
            match
        );

        const booleans = [
            'live',
            'enabled',
            'aggregate',
        ];

        match = booleans.reduce(
            (ms, key) => {
                if (key in filters) {
                    return {
                        ...ms,
                        [ key ]: !!+filters[ key ],
                    };
                }
                return ms;
            },
            match
        );

        const texts = [ 'id', 'title', 'url' ];

        match = texts.reduce(
            (ms, key) => {
                if (key in filters) {
                    return {
                        ...ms,
                        [ key ]: {
                            $regex: filters[ key ],
                            $options: 'gi'
                        },
                    };
                }
                return ms;
            },
            match
        );

        const dates = { 
            lastPolled: 'lastPolled',
            lastSuccessfulPoll: 'lastSuccessfulPoll',
            lastErrorDate: 'lastErrorDate',
        };

        const tzMinutes = 480;

        match = Object.keys(dates).reduce(
            (ms, key) => {
                if (key in filters) {

                    const [ from, to ] = filters[ key ];
                    const value = {};

                    if (from) {
                        value.$gte = new Date(
                            (new Date(from)).getTime() + tzMinutes * 60 * 1000
                        );
                    }

                    if (to) {
                        value.$lte = new Date(
                            (new Date(to)).getTime() + tzMinutes * 60 * 1000
                        );
                    }

                    return {
                        ...ms,
                        [ dates[ key ] ]: value,
                    };
                }
                return ms;
            },
            match
        );

        const numbers = [
            'fetchErrors',
        ];

        match = numbers.reduce(
            (ms, key) => {
                if (key in filters) {
                    return {
                        ...ms,
                        [ key ]: filters[ key ],
                    };
                }
                return ms;
            },
            match
        );

        let facet = {
            metadata: [
                { $match: match },
                { $group: {
                    _id: null,
                    total: { $sum: 1 },
                }},
            ],
            feeds: [
                { $match: match },
            ],
        };

        if (sort && sort.length) {
            sortObj = sort.reduce(
                (o, { field, value }) => ({
                    ...o,
                    [ field === 'publication' ? 'publication.name' : field ]: value,
                }),
                {}
            );
            facet.feeds.push({ $sort: sortObj });
        }

        facet.feeds = [
            ...facet.feeds,
            { $skip: pageSize * (page - 1) },
            { $limit: pageSize },
        ];

        if (1) {
            facet = {
                ...facet,
                publications: [
                    { $group: { _id: '$publication' }},
                    { $sort: { '_id.name': 1 }},
                ],
            };
        };

        if (withInput.publication) {
            pipeline = [
                ...pipeline, 
                { $lookup: {
                    from: 'publications',
                    localField: 'publicationId',
                    foreignField: '_id',
                    as: 'publication'
                }},
            ];
            project.publication = { $arrayElemAt: [ '$publication', 0 ]};

        } else {
            project.publicationId = 1;
        }

        if (withInput.tags) {
            pipeline = [
                ...pipeline,
                { $lookup: {
                    from: 'tags',
                    localField: 'tags',
                    foreignField: '_id',
                    as: 'tagEntities'
                }},
            ];
            project.tags = '$tagEntities';

        } else {
            project.tagIds = '$tags';
        }

        pipeline = [
            ...pipeline,
            { $project: project },
            {
                $facet: facet,
            },
            {
                $project: {
                    feeds: 1,
                    publications: 1,
                    total: { $arrayElemAt: [ '$metadata.total', 0 ] },
                },
            },
        ];

        const result = await coll.aggregate(pipeline).toArray();

        return result;
    }

    const _q = u.objectify(q);

    return coll.find(_q).toArray();
};

export const getBatch = async ({ coll, u }, { batchSize, inProgress, d }) => {

	return coll.aggregate([
		{ $lookup: {
			from: 'publications',
			localField: 'publicationId',
			foreignField: '_id',
			as: 'publication'
		}},
		{ $match: {
			$and: [
                { _id: { $nin: u.objectIds(inProgress) }},
                { fetchErrors: { $lt: 5 }},
				{
                    'publication.enabled': { $eq: true },
                    enabled: { $eq: true },
                },
				{
					$or: [
						{ lastPolled: { $exists: false }},
						{ lastPolled: { $lte: d }},
					],
				},
			],
		}},
		{ $project: {
			_id: 1,
			publication: { $arrayElemAt: [ '$publication', 0 ]},
			url: 1,
			title: 1,
			description: 1,
			tags: 1,
			scraper: 1,
			live: 1,
            enabled: 1,
			lastPolled: 1,
            fetchErrors: 1,
		}},
		{ $sort: { lastPolled: 1 }},
		{ $limit: batchSize },
	]).toArray();
};

export const getFeedsByIds = async ({ coll, u }, { ids }) => coll.find({
	_id: { $in: u.objectIds(ids) }
}).toArray();

export const getFeedsByPublicationIds = async ({ coll, u, m, r }, { publicationIds }) =>
    r.compose(
        r.andThen(r.map(u.unobjectify)),
        m.cursorToArray,
        r.bind(coll.find, coll)
    )({
        publicationId: { $in: u.objectIds(publicationIds) }
    });

export const updateFeed = async (
    { coll, u, m: { ObjectId } },
    { feedId, updates }
) => {

	if ('tags' in updates) {
		updates.tags = u.objectIds(updates.tags);
	}

	const { value } = await coll.findOneAndUpdate(
		{ _id: new ObjectId(feedId)},
		{ $set: updates },
		{ returnDocument: 'after' }
	);

	return value;
};

export const updateLastChecked = ({ updateFeed }, feedId) => updateFeed(
	{ feedId, updates: { lastChecked: new Date() }}
);

export const deleteFeed = async (
    { coll, publicationsColl, m: { ObjectId }},
    { feedId, publicationId }
) => {

    feedId = new ObjectId(feedId);

	const { deletedCount } = await coll.deleteOne({ _id: feedId });

	if (deletedCount === 1) {

		const { value } = await publicationsColl.findOneAndUpdate(
			{ _id: new ObjectId(publicationId) },
			{ $pull: { feeds: feedId }},
			{ returnDocument: 'after' }
		);

		return true;
	}

	return false;
};
