import { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import cn from 'classnames/bind';
import AutosizeInput from 'react-18-input-autosize';
import { compose as c } from 'ramda';
import { FontAwesomeIcon as Fa } from '@fortawesome/react-fontawesome';
import { useFloating, offset } from '@floating-ui/react-dom';
import ClickAwayListener from 'react-click-away-listener';

import { bindThis } from '@lib/utils/react';
import $socketio from '@state/socketio';
import $groupPanel from '@state/group-panel';
import $userGroups from '@state/user-groups';
import $groups from '@state/groups';
import $modal from '@state/modal';
import $users from '@state/users';

import i from '@ui/styles/icomoon.css';
import f from '@ui/styles/forms.scss';
import s from './styles.scss';
import z from '@ui/styles/base.scss';

const sx = cn.bind(s);
const si = cn.bind(i);
const sf = cn.bind(f);
const zx = cn.bind(z);

const Menu = ({
	x,
	y,
	open,
    refs,
	strategy,
    floatingStyles,
	editNameFn,
	setGroupItems,
	openModal,
	setMenuOpen,
	group,
}) => {

	if (!open) {
		return null;
	}

	return (
		<div
			ref={ refs.setFloating }
			style={ floatingStyles }
		>
			<div className={ sx('menu') }>
				<div
					className={ s.menuItem }
					onClick={
						() => {
							setMenuOpen(false);
							editNameFn();
						}
					}
				>
                    <span className={ s.itemIcon }>
                        <Fa icon='fa-solid fa-pencil' />
                    </span>
					<span className={ s.itemLabel }>Edit Name</span>
				</div>
				<div
					className={ s.menuItem }
					onClick={ () => {
						setGroupItems({ items: group.items });
						openModal({
							type: 'groupEditMembers',
							props: {
								group,
							},
						})
					}}
				>
                    <span className={ s.itemIcon }>
                        <Fa icon='fa-solid fa-plus-minus' />
                    </span>
					<span className={ s.itemLabel }>Add/Remove</span>
				</div>
				<div
					className={ s.menuItem }
					onClick={
						() => openModal({
							type: 'deleteGroup',
							props: {
								group,
							},
						})
					}
				>
                    <span className={ s.itemLabel }>
                        <Fa icon='fa-solid fa-trash-can' />
                    </span>
					<span className={ s.itemLabel }>Delete Group</span>
				</div>
			</div>
		</div>
	);
};

const ReadMode = ({
	group,
	editModes,
	editNameFn,
	openModal,
	setGroupItems
}) => {

	const mounted = useRef(false);

	useEffect(() => {
		mounted.current = true;

		return () => {
			mounted.current = false;
		};
	}, []);

	const { x, y, refs, floatingStyles, strategy, update } = useFloating({
		placement: 'bottom-end',
		middleware: [ offset(5) ],
	});

	const [ menuOpen, setMenuOpen ] = useState(false);

	return (
		<>
			<div className={ s.groupName }>
				{ group.name }
			</div>
			<ClickAwayListener
				onClickAway={ () => {
					if (mounted.current) {
						setMenuOpen(false);
					}
				}}
			>
				<div>
                    <div
                        className={ cn(sx('menuBtn'), zx('materialOutlined')) }
                        onClick={
							() => {
								setMenuOpen(!menuOpen);
								update();
							}
						}
						ref={ refs.setReference }
                    >
                        <Fa icon='fa-solid fa-ellipsis-vertical' />
                    </div>
					<Menu
						open={ menuOpen }
						x={ x }
						y={ y }
						refs={ refs }
						strategy={ strategy }
                        floatingStyles={ floatingStyles }
						editNameFn={ editNameFn }
						setGroupItems={ setGroupItems }
						openModal={ openModal }
						setMenuOpen={ setMenuOpen }
						group={ group }
					/>
				</div>
			</ClickAwayListener>
		</>
	);
};

const EditName = ({ groupName, onChangeName, updateNameFn, toggleEditMode }) => (
	<>
		<div className={ s.field }>
			<div
				className={ s.groupName }
				
			>
				<AutosizeInput
					autoFocus
					inputClassName={ sx('groupNameInput') }
					value={ groupName }
					onChange={ onChangeName }
				/>
			</div>
		</div>
		<div className={ s.actions }>
			<button
				title='Save'
				className={ cn(
					sx('groupToolbarBtn' ),
					si('icon-checkmark')
				)}
				onClick={ updateNameFn }
			></button>
			<button
				title='Cancel'
				className={ cn(
					sx('groupToolbarBtn' ),
					si('icon-blocked')
				)}
				onClick={ () => toggleEditMode('name') }
			></button>
		</div>
	</>
);

const EditMembers = ({ group, toggleEditMode, updateMembers }) => (
	<>
		<div className={ s.field }>
			<span
				className={ s.groupName }
			>
				{ group.name }
			</span>
		</div>
		<div className={ s.actions }>
			<button
				title='Save'
				className={ cn(
					sx('groupToolbarBtn' ),
					si('icon-checkmark')
				)}
				onClick={ updateMembers }
			></button>
			<button
				title='Cancel'
				className={ cn(
					sx('groupToolbarBtn' ),
					si('icon-blocked')
				)}
				onClick={ () => toggleEditMode('members') }
			></button>
		</div>
	</>
);

const updateName = (userId, group, socketId, groupName, updateGroup, toggleEditMode) => {

	updateGroup({
		userId,
		groupId: group._id,
        socketId,
		input: {
			name: groupName,
			items: group.items,
		},
	});

	toggleEditMode('name');
};

export default connect(
	(state, props) => ({
		group: $userGroups.selectors.getGroupById(state, props),
		sockets: $socketio.selectors.getSocketIdsByNsp(state, props),
	}),
	{
		openModal: $modal.actions.open,
		updateGroup: $groups.actions.updateGroupRequest,
		setGroupItems: $groupPanel.actions.setGroupItems,
	},
)(
	c(
		({
			userId,
			group,
            sockets,
			editModes,
			openModal,
			toggleEditMode,
			updateGroup,
			updateMembers,
			setGroupItems,
		}) => {

			const [ groupName, setGroupName ] = useState(group.name);

			let common;

			if (editModes.name) {
				common = (
					<EditName
						groupName={ groupName }
						onChangeName={ e => setGroupName(e.target.value) }
						updateNameFn={
							() => updateName(
								userId,
								group,
                                sockets['/poller'],
								groupName,
								updateGroup,
								toggleEditMode
							)
						}
						toggleEditMode={ toggleEditMode }
					/>
				);
			} else if (editModes.members) {
				common = (
					<EditMembers
						group={ group }
						toggleEditMode={ toggleEditMode }
						updateMembers={ updateMembers }
					/>
				);
			} else {
				common = (
					<ReadMode
						group={ group }
						editModes={ editModes }
						editNameFn={ () => toggleEditMode('name') }
						toggleEditMode={ toggleEditMode }
						openModal={ openModal }
						setGroupItems={ setGroupItems }
					/>
				);
			}

			return (
				<div className={ s.root }>
					{ common }
				</div>
			);
		},
	)
);
