import { connect } from 'react-redux';
import { compose as c } from 'ramda';
import cn from 'classnames/bind';

import $app from '@state/app';
import $users from '@state/users';
import $userGroups from '@state/user-groups';
import $modal from '@state/modal';

import Group from './ui/group-panel';

import s from './styles.scss';
import m from '@ui/styles/icomoon.css';

const sx = cn.bind(s);
const mx = cn.bind(m);

export default connect(
	(state, props) => ({
		user: $users.selectors.getLoggedInUser(state, props),
		groups: $userGroups.selectors.getGroups(state, props),
		responsive: $app.selectors.getResponsive(state, props),
	}),
	{
		openModal: $modal.actions.open,
	}
)(
	c(
		({ user, groups, responsive, openModal }) => (
			<div className={ sx('root', { mobile: !responsive.md }) }>
				<div className={ s.actionsBar }>
					<div
						className={ s.createBtn }
						onClick={ 
							() => openModal({
								type: 'createGroup',
								props: {},
							})
						}
					>
						<span
							title='Create Group'
							className={ m[ 'icon-folder-plus' ] }
						/>
                        <span>Create Group</span>
					</div>
				</div>
				{ !groups.length && 
					<div className={ s.emptyGroups }>
						No groups
					</div>
				}
				{ !!groups.length &&
					<ul className={ s.list }>
					{
						groups.map(g => 
							<Group
								key={ g._id }
								groupId={ g._id }
								userId={ user._id }
							/>
						)
					}
					</ul>
				}
			</div>
		),
	)
);
