import { useState, useEffect, useRef } from 'react';
import { useClickAway } from '@lib/utils/react';
import { useFloating, offset } from '@floating-ui/react-dom';
import cn from 'classnames/bind';

import Icon from '@ui/components/icon';

import s from './styles.scss';
import z from '@ui/styles/base.scss';

const sx = cn.bind(s);
const zx = cn.bind(z);

export default ({ layouts, toggle }) => {

    const mounted = useRef(false);
    const clickAwayRef = useRef(null);

	useEffect(() => {
		mounted.current = true;

		return () => {
			mounted.current = false;
		};
	}, []);

	const { x, y, refs, floatingStyles, strategy, update } = useFloating({
		placement: 'bottom-end',
		middleware: [ offset(5) ],
	});

	const [ menuOpen, setMenuOpen ] = useState(false);

    useClickAway(
        clickAwayRef,
        e => {
            if (mounted.current) {
                setMenuOpen(false);
            }
        },
        []
    );

	return (
		<div className={ s.root } ref={ clickAwayRef }>
            <div
                className={ cn(sx('menuBtn')) }
                onClick={
                    () => {
                        setMenuOpen(!menuOpen);
                        update();
                    }
                }
                ref={ refs.setReference }
            >
                <span className={ z.materialOutlined }>
                    view_quilt
                </span>
            </div>
            { menuOpen &&
            <div
                ref={ refs.setFloating }
                style={ floatingStyles }
            >
                <div className={ sx('menu') }>
                    <div
                        key={ layouts[ 0 ].id }
                        className={ sx('menuItem', { active: layouts[ 0 ].active }) }
                        title={ layouts[ 0 ].title }
                        onClick={ () => toggle(layouts[ 0 ].id) }
                    >
                        <div className={ cn(zx('materialOutlined'), sx('itemIcon')) }>
                            { layouts[ 0 ].icon.content }
                        </div>
                        <div className={ s.itemText }>List</div>
                    </div>
                    <div
                        key={ layouts[ 1 ].id }
                        className={ sx('menuItem', { active: layouts[ 1 ].active }) }
                        title={ layouts[ 1 ].title }
                        onClick={ () => toggle(layouts[ 1 ].id) }
                    >
                        <div className={ cn(zx('materialRounded'), sx('itemIcon')) }>
                            { layouts[ 1 ].icon.content }
                        </div>
                        <div className={ s.itemText }>Big List</div>
                    </div>
                </div>
            </div>
            }
		</div>
	);
};
