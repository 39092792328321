import { connect } from 'react-redux';
import { compose as c } from 'ramda';
import cn from 'classnames/bind';

import $users from '@state/users';
import $userGroups from '@state/user-groups';
import $socketio from '@state/socketio';
import $publications from '@state/publications';

import s from './styles.scss';
import m from '@ui/styles/icomoon.css';

const sx = cn.bind(s);
const mx = cn.bind(m);

const handleClick = (sockets, currentView, all, id, type, user, setCurrentView) => {

	const found = currentView.find(i => i.value === id);

	let newValue = found ?
		currentView.filter(i => i.value !== id) :
		[ ...currentView, { type, id } ];

	newValue = newValue.map(
		i => ('value' in i) ?
			({ type: i.type, id: i.value }) :
			i
	)

	setCurrentView({
		userId: user._id,
		socketId: sockets[ '/poller' ],
		input: {
			viewItems: newValue,
		},
	});
};

const PubRow = connect(
	(state, props) => ({
		sockets: $socketio.selectors.getSocketIdsByNsp(state, props),
		user: $users.selectors.getLoggedInUser(state, props),
		logoSrc: $publications.selectors.getLogoSrc(state, { ...props, dim: '200', bg: 'dark' }),
		currentView: $userGroups.selectors.selectedViewOpts(state, props),
		all: $userGroups.selectors.groupsPubsFeeds(state, props),
	}),
	{
		setCurrentView: $users.actions.setCurrentViewRequest,
	}
)(
	c(
		({
            sockets,
            currentView,
            logoSrc,
            pub,
            user,
            all,
            setCurrentView,
        }) => (
			<div
				className={ sx('pubRow', { active: false }) }
				onClick={
					() => handleClick(
						sockets,
						currentView,
						all,
						pub._id,
						'publication',
						user,
						setCurrentView
					)
				}
			>
				<span
					className={
						cn(
							sx(
								'check',
								{
									active: currentView.find(
										i => i.value === pub._id
									)
								}
							),
							mx('icon-checkmark')
						)
					}
				/>
				<span className={ s.pubIconCont }>
					<img src={ logoSrc } />
				</span>
				<span className={ s.pubName }>
					{ pub.name }
				</span>
			</div>
		)
	)
);

export default connect(
	(state, props) => ({
		sockets: $socketio.selectors.getSocketIdsByNsp(state, props),
		user: $users.selectors.getLoggedInUser(state, props),
		sockets: $socketio.selectors.getSocketIdsByNsp(state, props),
		currentView: $userGroups.selectors.selectedViewOpts(state, props),
		opts: $userGroups.selectors.getRemainingViewOpts(state, props),
		all: $userGroups.selectors.subGroupsPubsFeeds(state, props),
	}),
	{
		setCurrentView: $users.actions.setCurrentViewRequest,
	},
)(
	c(
		({
            user,
            sockets,
            currentView,
            setCurrentView,
            all,
            mobile,
            clickHandler,
            setMenuOpen,
        }) => (
			<div className={ sx('root', { mobile }) }>
                <div className={ s.clear } onClick={
                    () => {
                        setCurrentView({
                            userId: user._id,
                            socketId: sockets[ '/poller' ],
                            input: {
                                viewItems: [],
                            },
                        });
                        setMenuOpen(false);
                    }
                }>Clear all</div>
				<div className={ s.sectionLabel }>Groups</div>
				<ul className={ sx('list', 'groupList') }>
					{ !all.groups.length &&
						<div className={ s.emptyGroups }>
							You haven't created any groups
						</div> 
					}
					{
						!!all.groups.length && all.groups.map(g => (
							<li
								key={ g._id }
								className={ sx('groupRow') }
								onClick={
									() => handleClick(
										sockets,
										currentView,
										all,
										g._id,
										'group',
										user,
										setCurrentView,
									)
								}
							>
								<span
									className={
										cn(
											sx(
												'check',
												{
													active: currentView.find(
														i => i.type === 'group' &&
															i.value === g._id
													)
												}
											),
											mx('icon-checkmark')
										)
									}
								/>
								<span className={ s.folderIconCont }>
									<span className={ mx('icon-folder') }/>
								</span>
								<span className={ s.groupName }>{ g.name }</span>
							</li>
						))
					}
				</ul>
				<div className={ s.sectionLabel }>Publications & Feeds</div>
				<div className={ sx('pubsAndFeeds') }>
					{
						!all.pubsAndFeeds.length &&
							<div className={ s.emptySubs }>
								You haven't subscribed to anything
							</div>
					}
					{
						all.pubsAndFeeds.map(([ pub, feeds ]) => (
							<div key={ pub._id } className={ s.pubAndFeeds }>
								<PubRow publicationId={ pub._id } pub={ pub }/>
								{
									feeds && feeds.length > 1 &&
									!currentView.find(i => i.value === pub._id) &&
									<ul className={ sx('list', 'feedsList') }>
									{
										feeds.map(f => (
											<li
												key={ f._id }
												className={
													sx('feedRow', { active: false })
												}
												onClick={
													() => handleClick(
														sockets,
														currentView,
														all,
														f._id,
														'feed',
														user,
														setCurrentView,
													)
												}
											>
												<span
													className={
														cn(
															sx(
																'check',
																{
																	active: currentView.find(
														i => i.value === f._id
																	)
																}
															),
															mx('icon-checkmark')
														)
													}
												/>
												<span className={ s.feedTitle }>
													{ f.title }
												</span>
											</li>
										))
									}
									</ul>
								}
							</div>
						))
					}
				</div>
			</div>
		),
	)
);
