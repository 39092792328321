import { useState } from 'react';
import { connect } from 'react-redux';
import { compose as c } from 'ramda';
import cn from 'classnames/bind';

import { selectors as userGroupSels } from '@state/user-groups';
import { selectors as pubSels } from '@state/publications';

import s from './styles.scss';
import m from '@ui/styles/icomoon.css';

const sx = cn.bind(s);
const mx = cn.bind(m);

const ViewItem = connect(
	(state, props) => ({
		logoSrc: pubSels.getLogoSrc(
			state,
			{
				...props,
				publicationId: props.item.type === 'publication' ?
					props.item.obj._id :
					props.item.obj.publicationId,
				dim: '200',
				bg: 'dark',
			}
		),
	}),
	{
	},
)(
	c(
		({ item, logoSrc }) => (
			<li className={ s.item } >
				<div className={ s.icon }>
					{ item.type === 'group' && (
						<span className={ cn(sx('folder'), mx('icon-folder')) }/>
					)}
					{ item.type !== 'group' && 
						<img
							src={ logoSrc }
							className={ s.img }
						/>
					}
				</div>
				<div className={ s.label }>
					{ item.label }
				</div>
			</li>
		),
	)
);

export default connect(
	(state, props) => ({
		currentViewOptions: userGroupSels.selectedViewOpts(state, props),
	}),
	{
	},
)(
	c(
		({ currentViewOptions, logoSrc, clickHandler }) => (
			<ul className={ s.root } onClick={ clickHandler } data-comp='feedView' title='Feed Selection'>
				{
					currentViewOptions.map(o => {
						return <ViewItem key={ o.obj._id } item={ o } />
					})
				}
			</ul>
		),
	)
);
